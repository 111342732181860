import { Medewerkerrol } from '../../api/models';
import DataTable, { SortableOption } from '../../../../global/components/DataTable/DataTable';
import { useMapSelectMedewerkerrollenToTableData } from '../../hooks/table/useMapSelectMedewerkerollenToTableData/useMapSelectMedewerkerrollenToTableData';
import { FC } from 'react';

interface SelectMedewerkerrollenTable {
	medewerkerrollen: Medewerkerrol[];
	pageSize: number;
	onSearchChange?: (searchData: { [key: string]: string }) => void;
	onSortChange?: (sortData: { columnId: string; sort: SortableOption }) => void;
	onCheckedChange?: (id: string, checked: boolean) => void;
	sortData: {
		[key: string]: SortableOption;
	};
	selectedMedewerkerrollen: string[];
}

const SelectMedewerkerrollenTable: FC<SelectMedewerkerrollenTable> = ({
	medewerkerrollen,
	pageSize,
	onSearchChange,
	onSortChange,
	onCheckedChange,
	sortData,
	selectedMedewerkerrollen,
}) => {
	const tableData = useMapSelectMedewerkerrollenToTableData(medewerkerrollen, sortData, selectedMedewerkerrollen);

	return (
		<DataTable
			showHeaderWithNoItems
			data={tableData}
			tableTestId="selectMedewerkerrollenTable"
			pageSizes={[pageSize]}
			onSearchChange={onSearchChange}
			onSortChange={onSortChange}
			onCheckedChange={onCheckedChange}
		/>
	);
};

export default SelectMedewerkerrollenTable;
