import { SortableOption } from '../../components/DataTable/DataTable';
import { getPropertyValue } from '../getPropertyValue/getPropertyValue';

export const sortList = (key: string, value: SortableOption, list: any[]) => {
	return [...list].sort((a, b) => {
		const propertyA = getPropertyValue(a, key);
		const propertyB = getPropertyValue(b, key);

		if (![propertyA, propertyB].every(property => typeof property === 'string')) return 0;

		return (value === 'asc' ? 1 : -1) * propertyA.localeCompare(propertyB);
	});
};
