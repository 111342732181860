import { FC } from 'react';
import { useNavigate } from 'react-router';

import { Medewerkerrol } from '../../api/models';
import DataTable from '../../../../global/components/DataTable/DataTable';
import { useMapMedewerkerrollenToTableData } from '../../hooks/table/useMapMedewerkerrollenToTableData/useMapMedewerkerrollenToTableData';

export interface MedewerkerrollenTableProps {
	medewerkerrollen?: Medewerkerrol[];
	pageSize: number;
	itemsNum: number;
}

const MedewerkerrollenTable: FC<MedewerkerrollenTableProps> = ({ medewerkerrollen, pageSize, itemsNum }) => {
	const navigate = useNavigate();
	const tableData = useMapMedewerkerrollenToTableData(medewerkerrollen ?? null);

	const handleRowClick = (id: string) => {
		navigate(`/organisatiebeheer/medewerkerrollen/${id}`);
	};

	return (
		<DataTable
			data={tableData}
			tableTestId="medewerkerrollenTable"
			pageSizes={[pageSize]}
			totalItemsNum={itemsNum}
			onRowClick={handleRowClick}
		/>
	);
};

export default MedewerkerrollenTable;
