import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTablePages } from '../../../../../global/hooks/useTablePages/useTableData';
import { getReferentiedataNaam } from '../../../../../global/utils/translations/getReferentiedataNaam';
import { Medewerkerrol } from '../../../api/models';
import ChipsPopoutContent, {
	ChipsPopoutContentProps,
} from '../../../../communitybeheer/components/ChipsPopoutContent/ChipsPopoutContent';
import { TableRow } from '../../../../../global/components/DataTable/DataTableContentRow/DataTableContentRow';

const APPLICATIEROLLEN_MAX_CHARS = 60;

const getFinalNamesString = (names: string, maxChars: number) => {
	return names.length > maxChars ? `${names.slice(0, maxChars).trim()}...` : names;
};

const getApplicatierolNames = (medewerkerrol: Medewerkerrol) =>
	medewerkerrol.applicatierollen?.map(rol => (rol ? rol.naam : null)).filter(Boolean) ?? [];

export const useMapMedewerkerrollenToTableData = (medewerkerrolrollen: Medewerkerrol[] | null): TableData | null => {
	const { t } = useTranslation();

	const header = {
		labels: [
			t('global:domainShared.naam'),
			t('global:domainShared.bron'),
			t('global:domainShared.soort'),
			t('organisatie:organisatie.applicatierollen'),
		],
	};

	const getPage = useCallback((medewerkerrolrollen: Medewerkerrol[]): TableRow[] => {
		return medewerkerrolrollen.map(medewerkerrol => {
			const applicatierollenNames = getApplicatierolNames(medewerkerrol);
			const applicatierollenNamesString = applicatierollenNames.join(', ');
			const applicatierollenInfoData: ChipsPopoutContentProps['data'] = applicatierollenNames.map(c => ({
				label: c ?? '',
				iconOptions: {
					className: 'zds-color-secondary',
					library: 'fa-solid',
					name: 'user',
					size: 'xs',
				},
			}));
			return {
				id: medewerkerrol.id ?? '',
				cells: [
					{ mainText: medewerkerrol.naam ?? '' },
					{ mainText: medewerkerrol.idBron ?? '' },
					{ mainText: getReferentiedataNaam(t, 'MEDEWERKERROL', medewerkerrol.soort?.code) },
					{
						mainText: getFinalNamesString(applicatierollenNamesString, APPLICATIEROLLEN_MAX_CHARS),
						nowrap: true,
						infoContent:
							applicatierollenNames.length && applicatierollenNamesString.length > APPLICATIEROLLEN_MAX_CHARS ? (
								<ChipsPopoutContent
									data={applicatierollenInfoData}
									title={t('organisatie:medewerkerrollenPage.tableInfoPopoutHeader', { name: medewerkerrol.naam })}
								/>
							) : null,
					},
				],
			};
		});
	}, []);

	const pages = useTablePages(medewerkerrolrollen, getPage);

	if (!pages) return null;

	return {
		header,
		body: { rows: pages },
	};
};
