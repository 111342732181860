import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from '../../../../../../global/components/Form/Form';
import { FormSubmitData } from '../../../../../../global/components/Form/Form.types';
import { useAppDispatch, useAppSelector } from '../../../../../../global/redux/store';
import ErrorMessage from '../../../../../../global/components/ErrorMessage/ErrorMessage';
import { selectCurrentMedewerkerrolData, updateMedewerkerrol } from '../../../../redux/currentMedewerkerrolData';
import { useMedewerkerrolFormSchema } from '../../../../hooks/form/schema/useMedewerkerrolFormSchema';
import { Applicatierol } from '../../../../../../global/api/models';
import OrganisatieAPI from '../../../../api';
import { getErrorMessageForGetRequest } from '../../../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import DataTable, { SortableOption } from '../../../../../../global/components/DataTable/DataTable';
import { mapApplicatierollenToTableData } from '../../../../utils/mapApplicatierollenToTableData';
import { usePermissions } from '../../../../../../global/hooks/usePermissions/usePermissions';
import styles from './MedewerkerrolOverview.module.scss';
import { getPropertyValue } from '../../../../../../global/utils/getPropertyValue/getPropertyValue';
import { sortList } from '../../../../../../global/utils/sortList/sortList';

const PAGE_SIZE = 5;
const CHECKBOX_COLUMN_ID = 'assigned';

const MedewerkerrolOverview: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { currentMedewerkerrolData, loading, updating, error } = useAppSelector(selectCurrentMedewerkerrolData);
	const hasPermission = usePermissions();
	const [applicatierollen, setApplicatierollen] = useState<Applicatierol[] | null>(null);
	const [errorApplicatierollen, setErrorApplicatierollen] = useState('');
	const [searchData, setSearchData] = useState<{ [key: string]: string }>({});
	const [sortData, setSortData] = useState<{ [key: string]: SortableOption }>({});
	const [selectedApplicatierollen, setSelectedApplicatierollen] = useState<string[]>(
		currentMedewerkerrolData?.applicatierollen?.map(ar => ar.id) ?? []
	);

	const formSchema = useMedewerkerrolFormSchema(currentMedewerkerrolData);

	const finalApplicatierollen = useMemo(() => {
		if (!applicatierollen) return null;

		return getSortedApplicatierollen(
			getFilteredApplicatierollen(applicatierollen, searchData),
			sortData,
			selectedApplicatierollen
		);
	}, [applicatierollen, searchData, sortData]);

	useEffect(() => {
		getApplicatieRollen();
	}, []);

	const getApplicatieRollen = async () => {
		setErrorApplicatierollen('');

		try {
			const result = await OrganisatieAPI.getApplicatierollen();

			setApplicatierollen(result);
		} catch (error) {
			setErrorApplicatierollen(getErrorMessageForGetRequest(error));
		}
	};

	const handleOnSubmit = (data: FormSubmitData) => {
		if (currentMedewerkerrolData?.id && !updating && hasPermission('ORG_WIJ_ORG')) {
			const updateData = {
				id: currentMedewerkerrolData.id,
				payload: data,
				applicatierollen: selectedApplicatierollen,
			};
			void dispatch(updateMedewerkerrol(updateData));
		}
	};

	const handleSelectionChange = (id: string, checked: boolean) => {
		if (checked) {
			return setSelectedApplicatierollen(old => Array.from(new Set([...old, id])));
		}

		setSelectedApplicatierollen(old => old.filter(applicatierolId => applicatierolId !== id));
	};

	const handleSearchChange = (data: { [key: string]: string }) => {
		setSearchData(data);
	};

	const handleSortChange = (data: { columnId: string; sort: SortableOption }) => {
		setSortData(data.columnId ? { [data.columnId]: data.sort } : {});
	};

	const tableData = finalApplicatierollen
		? mapApplicatierollenToTableData(finalApplicatierollen, selectedApplicatierollen, sortData, CHECKBOX_COLUMN_ID, t)
		: null;

	return (
		<div className={styles.container}>
			{loading && <h3>{t('global:common.loading')}...</h3>}
			{!loading && formSchema && (
				<div data-testid="updateMedewerkerrolForm">
					<Form schema={formSchema} onSubmit={handleOnSubmit} formId="updateMedewerkerrolForm" />
				</div>
			)}
			{error && <ErrorMessage message={t(error)} />}
			{tableData && (
				<div className="zds-margin-top-16">
					<DataTable
						data={tableData}
						tableTestId="applicatierollenTable"
						pageSizes={[PAGE_SIZE]}
						header={{
							subheader: {
								Component: Headline,
								props: {},
							},
						}}
						onCheckedChange={handleSelectionChange}
						onSearchChange={handleSearchChange}
						onSortChange={handleSortChange}
						showHeaderWithNoItems
					/>
					{errorApplicatierollen && <ErrorMessage message={t(errorApplicatierollen)} />}
				</div>
			)}
		</div>
	);
};

const getFilteredApplicatierollen = (
	applicatierollen: Applicatierol[],
	searchData: {
		[key: string]: string;
	}
) => {
	let rollen: Applicatierol[] = applicatierollen;

	Object.entries(searchData).forEach(([key, value]) => {
		rollen = rollen.filter(rol => {
			const property = getPropertyValue(rol, key);
			if (typeof property !== 'string') return true;

			return property.trim().toLowerCase().includes(value.trim().toLowerCase());
		});
	});

	return rollen;
};

const groupApplicatierollen = (rollen: Applicatierol[], selectedApplicatierollen: string[]) => {
	const grouped: {
		top: Applicatierol[];
		bottom: Applicatierol[];
	} = { top: [], bottom: [] };

	rollen.forEach(rol => {
		grouped[selectedApplicatierollen.includes(rol.id) ? 'top' : 'bottom'].push(rol);
	});

	return grouped;
};

const getSortedApplicatierollen = (
	rollen: Applicatierol[],
	sortData: {
		[key: string]: SortableOption;
	},
	selectedApplicatierollen: string[]
) => {
	Object.entries(sortData).forEach(([key, value]) => {
		if (!key || !value || typeof value !== 'string') return;

		if (key === CHECKBOX_COLUMN_ID) {
			rollen = Object.values(groupApplicatierollen(rollen, selectedApplicatierollen)).flat();
			if (value === 'desc') rollen = rollen.reverse();
		} else {
			rollen = sortList(key, value, rollen);
		}
	});

	return rollen;
};

const Headline: FC = () => {
	const { t } = useTranslation();

	return <div className="zds-headline-xl ">{t('organisatie:organisatie.applicatierollen')}</div>;
};

export default MedewerkerrolOverview;
