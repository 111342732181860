import { createAsyncThunk } from '@reduxjs/toolkit';

import OrganisatieAPI from '../../api';
import { getErrorMessageForGetRequest } from '../../../../global/utils/errorHandlers/getErrorMessageForGetRequest';
import { Medewerkerrol } from '../../api/models';

export const getMedewerkerrollen = createAsyncThunk<Medewerkerrol[], void, { rejectValue: string }>(
	'organisatieData/getMedewerkerrollen',
	async (_, { rejectWithValue }) => {
		return OrganisatieAPI.getMedewerkerrollen().catch(error => {
			return rejectWithValue(getErrorMessageForGetRequest(error));
		});
	}
);
