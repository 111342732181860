import { Medewerkerrol } from '../../../api/models';
import { SortableOption, TableData } from '../../../../../global/components/DataTable/DataTable';
import { useTranslation } from 'react-i18next';

export const useMapSelectMedewerkerrollenToTableData = (
	medewerkerrollen: Medewerkerrol[],
	sortData: { [key: string]: SortableOption },
	checkedMedewerkerrollen: string[]
): TableData | null => {
	const { t } = useTranslation();

	const columnIds = ['naam', 'assigned'];

	const header = {
		labels: [t('global:domainShared.naam'), t('global:common.assigned')],
		columnIds,
		sortable: columnIds.map(id => (id ? sortData[id] || true : undefined)) as SortableOption[],
		width: [100, 50],
	};

	const body = {
		rows: medewerkerrollen.map(medewerkerrol => {
			return {
				id: medewerkerrol.id ?? '',
				cells: [
					{ mainText: medewerkerrol.naam ?? '' },
					{ mainText: '', checkbox: { checked: checkedMedewerkerrollen.includes(medewerkerrol.id) } },
				],
			};
		}),
	};

	return {
		header,
		body,
	};
};
