import { createSlice } from '@reduxjs/toolkit';
import { MedewerkerrollenState, getMedewerkerrollen } from '.';
const initialState: MedewerkerrollenState = { itemsNum: 0, medewerkerrollen: null, loading: false, error: null };

export const medewerkerrollenSlice = createSlice({
	name: 'medewerkerrollen',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getMedewerkerrollen.pending, state => {
				state.loading = true;
				state.error = null;
				state.medewerkerrollen = null;
				state.itemsNum = 0;
			})
			.addCase(getMedewerkerrollen.fulfilled, (state, { payload }) => {
				state.loading = false;
				state.itemsNum = payload.length;
				state.medewerkerrollen = payload;
			})
			.addCase(getMedewerkerrollen.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload ?? null;
			});
	},
});

export const medewerkerrollenReducer = medewerkerrollenSlice.reducer;
