import Constants from '../../../auth.config';

const createRandomState = () => {
	const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let array = new Uint8Array(40);
	window.crypto.getRandomValues(array);
	array = array.map(x => validChars.charCodeAt(x % validChars.length));
	return String.fromCharCode.apply(null, [...array]);
};

export const setStateAndRedirectToLogin = () => {
	const state = createRandomState();
	localStorage.setItem('state', state);
	localStorage.setItem(Constants.originPathLsKey, window.location.pathname);

	window.location.href = `${Constants.loginUrl}?redirect_uri=${encodeURIComponent(
		Constants.callbackUrl
	)}&state=${state}&collapse=1`;
};
