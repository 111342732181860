import { FormSubmitData } from '../../../global/components/Form/Form.types';
import api from '../../../axiosConfig';
import { Medewerker, Medewerkerrol, Organisatie } from './models';
import { Rechtspersoon } from '../../relatiebeheer/api/models';
import { ListResponse, getListResponse } from '../../../global/api';
import { Applicatierol } from '../../../global/api/models';

export const Endpoints = {
	applicatierollen: 'organisaties/v1/applicatierollen',
	organisaties: 'bor/v1/organisaties',
	medewerkers: 'bor/v1/medewerkers',
	medewerkerrollen: 'bor/v1/medewerkerrollen',
	relaties: 'relaties/v1/relaties',
};

const getOrganisaties = async (term?: string, offset = 0, limit = 20): Promise<ListResponse<Organisatie>> => {
	const $filter = term ? `relatie[naam] contains ${term}` : null;
	const response = await api.get<Organisatie[]>(Endpoints.organisaties, {
		params: {
			$filter,
			pagina: offset,
			//expand: ['organisatorischeEenheden', 'relatie'],
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getOrganisatie = async (id: string): Promise<Organisatie> => {
	const response = await api.get<Organisatie>(`${Endpoints.organisaties}/${id}`);

	return response.data;
};

const updateOrganisatie = async (id: string, body: FormSubmitData): Promise<Organisatie> => {
	const response = await api.patch<Organisatie>(`${Endpoints.organisaties}/${id}`, body);

	return response.data;
};

const createOrganisatie = async (body: FormSubmitData): Promise<string> => {
	const response = await api.post<{ id: string }>(Endpoints.organisaties, body);

	return response.data.id;
};

const getRelatieMedewerkers = async (relatieId: string): Promise<Medewerker[]> => {
	const response = await api.get<Medewerker[]>(Endpoints.medewerkers, {
		params: {
			$filter: `relatie eq ${relatieId}`,
			pagina: 0,
		},
		headers: {
			'x-parameters-aantalperpagina': 1,
		},
	});

	return response.data;
};

const getMedewerkers = async (term?: string, offset = 0, limit = 20): Promise<ListResponse<Medewerker>> => {
	const $filter = term ? `relatie[naam] contains ${term}` : null;
	const response = await api.get<Medewerker[]>(Endpoints.medewerkers, {
		params: {
			$filter,
			pagina: offset,
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return getListResponse(response);
};

const getMedewerker = async (id: string): Promise<Medewerker> => {
	const response = await api.get<Medewerker>(`${Endpoints.medewerkers}/${id}`);

	return response.data;
};

const updateMedewerker = async (
	id: string,
	payload: FormSubmitData,
	medewerkerollen: string[]
): Promise<Medewerker> => {
	const body = { ...payload, rollen: medewerkerollen.map(id => ({ id })) };

	const response = await api.patch<Medewerker>(`${Endpoints.medewerkers}/${id}`, body);

	return response.data;
};

const createMedewerker = async (body: FormSubmitData): Promise<string> => {
	const response = await api.post<{ id: string }>(Endpoints.medewerkers, body);

	return response.data.id;
};

const getRechtspersoonRelaties = async (term?: string, offset = 0, limit = 20): Promise<Rechtspersoon[]> => {
	const filterRelatieNaam = term ? ` and naam contains ${term}` : '';

	const response = await api.get<Rechtspersoon[]>(Endpoints.relaties, {
		params: {
			pagina: offset,
			$filter: `soort eq REC${filterRelatieNaam}`,
			$fields: ['naam', 'id', 'soort'],
			expand: '',
		},
		headers: {
			'x-parameters-aantalperpagina': limit,
		},
	});

	return response.data;
};

const getMedewerkerrollen = async (): Promise<Medewerkerrol[]> => {
	const response = await api.get<Medewerkerrol[]>(Endpoints.medewerkerrollen);

	return response.data;
};

const getMedewerkerrol = async (id: string): Promise<Medewerkerrol> => {
	const response = await api.get<Medewerkerrol>(`${Endpoints.medewerkerrollen}/${id}`);

	return response.data;
};

const createMedewerkerrol = async (body: FormSubmitData): Promise<string> => {
	const response = await api.post<{ id: string }>(Endpoints.medewerkerrollen, body);

	return response.data.id;
};

const updateMedewerkerrol = async (
	id: string,
	payload: FormSubmitData,
	applicatierollen: string[]
): Promise<Medewerkerrol> => {
	const body = { ...payload, applicatierollen: applicatierollen.map(id => ({ id })) };
	const response = await api.patch<Medewerkerrol>(`${Endpoints.medewerkerrollen}/${id}`, body);

	return response.data;
};

const getApplicatierollen = async (): Promise<Applicatierol[]> => {
	const response = await api.get<Applicatierol[]>(Endpoints.applicatierollen);

	return response.data;
};

export default {
	getOrganisaties,
	getOrganisatie,
	updateOrganisatie,
	createOrganisatie,
	getRelatieMedewerkers,
	getMedewerkers,
	getMedewerker,
	createMedewerker,
	updateMedewerker,
	getRechtspersoonRelaties,
	getMedewerkerrollen,
	getMedewerkerrol,
	createMedewerkerrol,
	updateMedewerkerrol,
	getApplicatierollen,
};
